<template>
  <div class="container legal-text">
    <section>
      <h1>Impressum</h1>
      <h2>Angaben gemäß § 5 TMG</h2>
    </section>

    <div class="v-grid">
      <section class="col">
        <h4>Kontakt</h4>
        <address>
          MEDIAFORMAT GmbH<br />
          Trippstadter Str. 122<br />
          67663 Kaiserslautern<br />
          Telefon:<a href="tel:+49631205755221"> 0631205755221</a><br />
          E-Mail:<a href="mailto:kontakt@mediaformat.de">
            kontakt@mediaformat.de</a
          ><br />
        </address>
      </section>
      <section class="col">
        <h4>Registereintrag</h4>
        HRB 31522, Amtsgericht Kaiserslautern
      </section>
      <section class="col">
        <h4>Geschäftsführer</h4>
        Arthur Ort, Eugen Fritzler
      </section>
      <section class="col">
        <h4>USt-Nr</h4>
        DE291253453
      </section>
    </div>

    <section>
      <h3>Haftung für Inhalte</h3>
      <p>
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die
        Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
        jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
        Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
        Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
        Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
        gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
        forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
        Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
        entfernen. Haftung für Links Unser Angebot enthält Links zu externen
        Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
        können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für
        die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
        Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
        Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
        erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten
        ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
        zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
        Links umgehend entfernen.
      </p>
    </section>

    <section>
      <h3>Urheberrecht</h3>
      <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
        sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
        werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Inhalte umgehend entfernen.
      </p>
    </section>

    <section>
      <h3>Datenschutz</h3>
      <p>
        Die Nutzung unserer Webseite ist in der Regel ohne Angabe
        personenbezogener Daten möglich. Soweit auf unseren Seiten
        personenbezogene Daten (beispielsweise Name, Anschrift oder
        eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
        freiwilliger Basis. Diese Daten werden nicht an Dritte weitergegeben.
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </p>
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
        angeforderter Werbung und Informationsmaterialien wird hiermit
        ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
        ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
        Werbeinformationen, etwa durch Spam-Mails, vor.
      </p>
    </section>

    <section>
      <h3>Online-Streitbeilegung</h3>
      <p>
        Die Europäische Kommission stellt unter
        https://ec.europa.eu/consumers/odr/ eine Plattform zur
        Online-Streitbeilegung bereit, die Verbraucher für die Beilegung einer
        Streitigkeit nutzen können und auf der weitere Informationen zum Thema
        Streitschlichtung zu finden sind. Außergerichtliche Streitbeilegung Wir
        sind weder verpflichtet noch dazu bereit, im Falle einer Streitigkeit
        mit einem Verbraucher an einem Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teilzunehmen.
      </p>
    </section>

    <section class="pb-3">
      <h3>Bild- und Videonachweis</h3>
      <ul>
        <li>
          shutterstock: ©baranq, ©Gorodenkoff, ©Grigvovan, ©PR Image Factory,
          ©YAKOBCHUK VIACHESLAV, ©dotshock, ©Kaspars Grinvalds, ©Gemenacom
          shutterstock.com
        </li>
        <li>
          Musik im Video Projekt Hausboot: Apollo by YouTube Audio Library -
          Free Copyright Line Music
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import { layoutState } from "@/components/layouts/layoutStyles";
import { metaInfo } from "@/utils/titleHelper";

export default {
  name: "Impressum",
  metaInfo() {
    return metaInfo(this.$t("title"), this.$t("descr"));
  },
  created() {
    layoutState.setPageConfig("light", true);
  },
  i18n: {
    messages: {
      de: {
        title: "Impressum",
        descr: "Angaben gemäß § 5 TMG"
      },
      en: {
        title: "Imprint",
        descr: "Angaben gemäß § 5 TMG"
      }
    }
  }
};
</script>
